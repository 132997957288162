/* font -------------------------------*/
/* Marcellus*/
@import url("https://fonts.googleapis.com/css2?family=Marcellus&display=swap");
/* Karla*/
@import url("https://fonts.googleapis.com/css2?family=Karla:wght@300&display=swap");
body {
  background-color: white;
  overflow-x: hidden;
}
@media (prefers-color-scheme: dark) {
  body {
    background: #fff;
  }
}
:root {
  color-scheme: light only;
}
/* App.js */
.App {
  font-family: "Marcellus", serif;
}

/* MyHeader.js */

.MyHeader {
  /* position: fixed; */
  position: absolute;
  white-space: nowrap;
  padding-top: 10px;
  padding-bottom: 10px;
  backdrop-filter: saturate(100%) blur(30px);
  z-index: 3;
}

.HeaderLogo {
  margin-left: 30%;
  width: 7vw;
}

.HeaderContainer {
  width: 100vw;
  text-align: left;
  align-items: center;
  display: flex;
  /* justify-content: center; */
}

.LinkContainer {
  margin-left: 5%;
}
.HeaderLink {
  text-decoration: none;
  color: white;
  margin-left: 10%;
  font-size: 1.2vw;
}
.PanelBackgroundImg {
  width: 40vw;
  position: fixed;
}
.MobilePanelBackgroundImg {
  width: 100vw;
  position: fixed;
}
.PanelToggle {
  cursor: pointer;
  position: absolute;
  height: 7vh;
  bottom: 2vh;
  right: 3vw;
  top: 2vh;
  /* z-index: 3; */
}
.MobilePanelToggle {
  cursor: pointer;
  position: absolute;
  height: 4vh;
  right: 3vw;
  top: 1.3vh;
  /* z-index: 3; */
}

.PanelLink {
  color: black;
  left: 50vw;
  position: absolute;
  font-size: 6vh;
  text-decoration: none;
}
.PanelLink:hover {
  text-decoration: underline;
  text-decoration-thickness: 1vh;
  text-decoration-color: #00d1ff;
}
.panel-content {
  background-color: white;
}
/* PageHeader.js */
.HeaderName {
  position: absolute;
  left: 50vw;
  transform: translate(-50%, 0%);
  font-size: 1.5vw;
}
.MobilePanelLink {
  color: black;
  left: 23vw;
  position: absolute;
  font-size: 4vh;
  text-decoration: none;
}
.MobileHeaderName {
  position: absolute;
  left: 50vw;
  transform: translate(-50%, 0%);
  font-size: 1.8vh;
}

.MobilePageHeader {
  /* position: fixed; */
  position: absolute;
  white-space: nowrap;
  padding-top: 10px;
  padding-bottom: 10px;
  /* backdrop-filter: saturate(100%) blur(30px); */
  z-index: 5;
}

.MobileHeaderLogo {
  height: 4vh;
  margin-left: 1.5vh;
}
/* Visualize.js */

.VisualizerContainer {
  place-items: center;
  display: grid;
}

.NeonMotion {
  width: 50vw;
  margin-left: 3vw;
}

.TypoMotion {
  width: 50vw;
}

.MobileVisualizerContainer {
  overflow-x: hidden;
}
.MobileNeonMotion {
  top: 5vh;
  height: 70vh;
  left: 53vw;
  position: fixed;
  transform: translate(-50%, 0%);
  z-index: 1;
}
.MobileTypoMotion {
  margin-top: 70vh;
  left: 0;
  width: 100vw;
  position: fixed;
  z-index: 3;
}
.MobileMainBtnContainer {
  margin-top: 62vh;
  left: 35vw;
  width: 100vw;
  position: fixed;
  z-index: 5;
}
@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

.puff-in-center {
  -webkit-animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s
    both;
  animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s both;
}
/* MainPage.js */
.MainBackground {
  position: absolute;
  width: 100vw;
}

.MainpageContainer {
  /* width: 100vw; */
  /* overflow: hidden; */
  margin: 0px auto;
  position: relative;
}

.MainHeader {
  position: absolute;
  top: 0%;
  width: 100%;
}
.MainText_1 {
  position: absolute;
  color: #00d1ff;
  margin-top: 75vh;
  font-size: 9vh;
  margin-left: 20vw;
  letter-spacing: 15px;
  text-shadow: 2px 2px 20px rgb(51, 51, 51);
}
.MainText_2 {
  position: absolute;
  color: white;
  margin-top: 75vh;
  font-size: 9vh;
  margin-left: 55vw;
  letter-spacing: 15px;
  text-shadow: 2px 2px 20px rgb(51, 51, 51);
}

.MobileMainText_1 {
  position: absolute;
  color: #00d1ff;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, 0%);
  font-size: 5vh;

  letter-spacing: 5px;
  text-shadow: 2px 2px 15px rgb(51, 51, 51);
}
.MobileMainText_2 {
  position: absolute;
  color: white;
  top: 57vh;
  left: 50vw;
  transform: translate(-50%, 0%);
  font-size: 5vh;

  letter-spacing: 5px;
  text-shadow: 2px 2px 15px rgb(51, 51, 51);
}
/* BrandStoryPage.js */

.BrandPageSlide {
  margin-top: 10vh;
  height: 90vh;
}
.BrandPageSlide2 {
  margin-top: 10vh;
  height: 80vh;
}
.MobileBrandPageSlide {
  margin-top: 5vh;
  height: 80vh;
}
.MobileBSImg {
  height: 100vh;
}

/* EcobottlePage.js */

.EcoBottleSlide {
  margin-top: 10vh;
  height: 90vh;
}
.EcoBottleSlide2 {
  margin-top: 15vh;
  height: 80vh;
}
.page {
  text-align: center;
}
.MobileEcoBottleSlide {
  margin-top: 5vh;
  height: 80vh;
}

/* MyFooter.js*/
.MyFooter {
  position: relative;
  width: 100vw;
  margin-top: 10vh;
  bottom: 0;

  z-index: 2;
}
.FooterLine {
  display: flex;
  font-family: "Karla", sans-serif;
  font-size: 13px;
  margin-left: 2%;
  margin-bottom: -25px;
}
.FooterImage {
  height: 40px;
}
.FooterTitle {
  color: #a3a4a6;
}
.FooterBorder {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 150;
  margin-bottom: 50;
  height: 0.8px;
  color: #a3a4a6;
  background: #a3a4a6;
}

/* Contact.js */
.ContactPageContainer {
  z-index: 2;
  width: 100vw;
  position: relative;
  /* background-color: #ffffff; */
}
.ContactPageTitle {
  padding-top: 10vh;
  padding-bottom: 5vh;
  text-align: center;
  font-size: 7vh;
  color: #00a8d6;
}
.ContactIndex {
  margin-left: 23vw;
  position: absolute;
}
.ContactRow {
  margin-bottom: 15px;
}
.RequestInput {
  margin-left: 30vw;
  font-family: "Marcellus", serif;
  height: 4vh;
  width: 40vw;
}
.RequestInput_Contents {
  height: 30vh;
  resize: none;
  font-family: "Marcellus", serif;
}
.ContactRow {
  display: flex;
  align-items: center;
}
.SubmitBtn {
  margin-left: 66.4vw;
  max-width: 4vw;
}
.ContactMotionVideos {
  top: 0;
  position: absolute;
  width: 50vw;
  z-index: 3;
}

.ContactMotionOrbit {
  position: absolute;
  height: 30vh;
  bottom: 22vh;
}

.ContactMotionCloud {
  position: absolute;
  top: 5vh;
  right: 0;
  width: 25vw;
}

.MobileRequestInput {
  margin-left: 10vw;
  font-family: "Marcellus", serif;
  height: 4vh;
  width: 80vw;
  position: relative;
  z-index: 3;
  border-width: 1px;
  border-radius: 10px;
}

.MobileRequestInput_Contents {
  height: 30vh;
  resize: none;
  font-family: "Marcellus", serif;
  z-index: 3;
  position: relative;
  border-radius: 10px;
}
.MobileContactPageContainer {
  z-index: 2;
  width: 100vw;
  /* background-color: #ffffff; */
}
.MobileSubmitBtn {
  right: 10vw;

  position: absolute;
}
.MobileContactRow {
  /* display: flex; */
}
.MobileContactIndex {
  margin-left: 10vw;
  margin-bottom: 1vh;
  margin-top: 3vh;
  position: relative;
  z-index: 3;
}
.MobileContactPageTitle {
  padding-top: 10vh;
  padding-bottom: 2vh;
  text-align: center;
  font-size: 3.2vh;
  color: #00a8d6;
  position: relative;
  z-index: 3;
}
.MobileContactMotionOrbit {
  height: 10vh;
  top: 8vh;
  z-index: 1;
  position: absolute;
}

.MobileContactMotionCloud {
  position: absolute;
  z-index: 1;
  top: 10vh;
  right: 0;
  width: 70vw;
}
/* 스크롤바 커스텀 */

/* 스크롤바의 폭 너비 */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(135, 221, 255); /* 스크롤바 색상 */
  border-radius: 10px; /* 스크롤바 둥근 테두리 */
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgb(126, 201, 231);
}
::-webkit-scrollbar-thumb:active {
  background-color: rgb(109, 176, 202);
}

::-webkit-scrollbar-track {
  background: rgba(220, 20, 60, 0.1); /*스크롤바 뒷 배경 색상*/
}
